import '@amfament/embedded-components/add-vehicle/add-vehicle';

import { flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { LoadingOverlay } from '@ecp/features/servicing/shared/components';
import { MODAL_FLOW, useModalPath } from '@ecp/features/servicing/shared/routing';
import {
  showAddVehicle,
  useBillingAccount,
  usePolicy,
  usePreferences,
} from '@ecp/features/servicing/shared/state';
import {
  IconCardBusinessOccupation,
  IconCardCommuteToWorkSchool,
  IconCardVacationPleasure,
} from '@ecp/themes/base';

import type { IconConfig } from '../MfeModal';
import { MfeModal } from '../MfeModal';
import { useStyles } from './AddVehicleModal.styles';

const icons: IconConfig[] = [
  { name: 'carOnRoad', value: IconCardVacationPleasure },
  { name: 'carInCity', value: IconCardCommuteToWorkSchool },
  { name: 'briefcase', value: IconCardBusinessOccupation },
];

const AddVehicleModalContent: React.FC<{ policyNumber: string }> = (props) => {
  const { classes } = useStyles();
  const modalPath = useModalPath();

  const { isPaperlessOn, isLoading: isLoadingPaperless } = usePreferences(props.policyNumber, {
    throwOnError: false,
  });

  if (isLoadingPaperless) {
    return <LoadingOverlay />;
  }

  return (
    <MfeModal
      title='Add a vehicle'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      element={<add-vehicle />}
      elementName='add-vehicle'
      icons={icons}
      isPaperlessOn={isPaperlessOn}
      useMock={env.mockapi?.addVehicle}
      classes={classes}
      {...modalPath}
    />
  );
};

const AddVehicleModalIfEligible: React.FC = () => {
  const { policyNumber } = useModalPath();
  const { billingAccount } = useBillingAccount({ policyNumber });
  const { policyData } = usePolicy(policyNumber);
  const { showAddVehicleLink } = showAddVehicle(billingAccount, policyData);

  return showAddVehicleLink ? <AddVehicleModalContent policyNumber={policyNumber!} /> : null;
};

export const AddVehicleModal: React.FC = () => {
  const modalPath = useModalPath();

  if (modalPath.currentFlow === MODAL_FLOW.ADD_VEHICLE && flagValues.ADD_VEHICLE)
    return <AddVehicleModalIfEligible />;
  else return null;
};
