import { forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';

import { IconButton, InputAdornment } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { DatePickerProps as MuiDatePickerPropsBase } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { Dayjs } from 'dayjs';
import dayjs, { isDayjs } from 'dayjs';

import { isMasked, noop } from '@ecp/utils/common';
import { DateConstants } from '@ecp/utils/date';
import { mergeRefs } from '@ecp/utils/web';

import { useTheme } from '@ecp/themes/base';
import { IconUIArrowLeft, IconUIArrowRight, IconUICalendar } from '@ecp/themes/base';

import type { TextFieldProps } from '../TextField';
import { TextField } from '../TextField';
import { useStyles } from './DatePicker.styles';

type InputValue = Dayjs | string | null | undefined;
type MuiDatePickerProps = MuiDatePickerPropsBase<InputValue, Dayjs>;
export interface DatePickerProps
  extends Pick<MuiDatePickerProps, 'className' | 'disabled' | 'maxDate' | 'minDate' | 'open'>,
    Pick<
      TextFieldProps,
      | 'autoComplete'
      | 'defaultValue'
      | 'error'
      | 'fullWidth'
      | 'helperText'
      | 'id'
      | 'label'
      | 'margin'
      | 'name'
      | 'onFocus'
      | 'placeholder'
      | 'required'
    > {
  dialogClassName?: string;
  popperClassName?: string;
  /** `data-testid` for TextField component. */
  'data-testid'?: string;
  /** Format string. */
  format?: MuiDatePickerProps['inputFormat'];
  /** Whether Popup/Dialog should show up or not. MUI DatePicker `disableOpenPicker` prop. */
  hidePicker?: MuiDatePickerProps['disableOpenPicker'];
  inputRef?: NonNullable<MuiDatePickerProps['InputProps']>['inputRef'];
  value?: string | null;
  /** Provides full control to TextField component props. */
  inputProps?: TextFieldProps; // TODO Might be unnecessary
  /** Provides capacity for grouplable consistently with other customer input type */
  groupLabel?: string;
  /**
   * Replacement for KeyboardDatePicker['onChange'] event,
   * except the date value is formatted as string.
   */
  actionOnChange?(value: string | null): void;
  /**
   * Replacement for both KeyboardDatePicker['onBlur'] and KeyboardDatePicker['onAccept'] events,
   * except the date value is unwrapped from the Event and formatted as string.
   */
  actionOnComplete?(value: string | null): void;
  onOpen?: () => unknown;
}

type ArrowButtonProps = {
  arrowDirection: 'left' | 'right';
  pickerDate: InputValue;
  onClick: (...args: unknown[]) => unknown; // passthru to Mui X DatePicker
  updatePickerDate: () => unknown;
};

/** Custom arrow button to enable tracking of picker date to inject date in between left/right arrows */
const ArrowButton: React.FC<ArrowButtonProps> = memo((props) => {
  const { arrowDirection, onClick, pickerDate, updatePickerDate, ...rest } = props;
  const { classes } = useStyles();
  const isRightArrow = arrowDirection === 'right';
  const ArrowIcon = isRightArrow ? IconUIArrowRight : IconUIArrowLeft;

  const handlePickerMonthChange = useCallback(
    (args: React.MouseEvent<HTMLButtonElement>) => {
      onClick(args);
      updatePickerDate();
    },
    [onClick, updatePickerDate],
  );

  const formattedDate = useMemo(() => dayjs(pickerDate).format('MMMM YYYY'), [pickerDate]);

  return (
    <>
      {isRightArrow && (
        <p className={classes.monthPickerText} aria-label={`Viewing calendar for ${formattedDate}`}>
          {formattedDate}
        </p>
      )}
      <IconButton {...rest} onClick={handlePickerMonthChange} className={classes.pickerIcon}>
        <ArrowIcon />
      </IconButton>
    </>
  );
});

export const DatePicker: React.FC<DatePickerProps> = memo(
  forwardRef((props, ref) => {
    const {
      actionOnChange = noop,
      actionOnComplete = noop,
      autoComplete,
      className,
      dialogClassName,
      popperClassName,
      'data-testid': testId,
      defaultValue,
      disabled = false,
      error,
      format = DateConstants.DISPLAY_FORMAT,
      fullWidth,
      helperText,
      hidePicker,
      id,
      inputProps,
      inputRef,
      label,
      groupLabel,
      margin,
      maxDate,
      minDate,
      name,
      onOpen = noop,
      onFocus = noop,
      placeholder = DateConstants.DISPLAY_FORMAT,
      required,
      value,
    } = props;
    const { classes, cx } = useStyles();
    const textFieldRef = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs(textFieldRef, ref, inputRef);
    const theme = useTheme();

    const isValueMasked = isMasked(value);

    const dateValue = useMemo(() => {
      if (!value) return null;
      if (isValueMasked) return value;

      return dayjs(value, DateConstants.ANSWERS_FORMAT);
    }, [isValueMasked, value]);

    const [open, setOpen] = useState(false);
    const [pickerDate, setPickerDate] = useState<Dayjs>(isDayjs(dateValue) ? dateValue : dayjs());

    const handleChange = useCallback<NonNullable<MuiDatePickerProps['onChange']>>(
      (val): void => {
        // When value gets deleted from the text field or has asterisks force it to null
        // in actionOnChange callback
        const newValue = val && !isMasked(val) ? val.format(DateConstants.ANSWERS_FORMAT) : null;
        actionOnChange(newValue);
      },
      [actionOnChange],
    );

    // This event is needed when the date is selected from the calender
    // instead of typing out. Kind of replacement for onBlur
    const handleAccept = useCallback<NonNullable<MuiDatePickerProps['onAccept']>>(
      (val): void => {
        const newValue = val ? val.format(DateConstants.ANSWERS_FORMAT) : null;
        actionOnComplete(newValue);
      },
      [actionOnComplete],
    );

    // Without this handler, onBlur is not working as expected
    // because of the difference in date formats between validator
    // and how the UI expects it
    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        // Stop here when date is represented by masked string and value hasn't been changed
        if (isMasked(event.target.value)) return;

        const newDate = dayjs(event.target.value, format, true);
        const newValue = newDate.isValid() ? newDate.format(DateConstants.ANSWERS_FORMAT) : null;

        actionOnComplete(newValue ?? event.target.value);
      },
      [format, actionOnComplete],
    );

    // custom picker value handlers
    const handleOpen = useCallback((): void => {
      setPickerDate(isDayjs(dateValue) ? dateValue : dayjs());
      setOpen(true);
      onOpen?.();
    }, [dateValue, onOpen]);

    const handleClose = useCallback((): void => {
      setOpen(false);
    }, []);
    const addPickerMonth = useCallback(
      () => setPickerDate((date) => dayjs(date).add(1, 'month')),
      [],
    );
    const subtractPickerMonth = useCallback(
      () => setPickerDate((date) => dayjs(date).subtract(1, 'month')),
      [],
    );

    const renderInput: MuiDatePickerProps['renderInput'] = useCallback(
      (props) => {
        const inputIcon = props.InputProps?.endAdornment || (
          <InputAdornment
            position='end'
            className={classes.mobileCalendarIcon}
            onClick={handleOpen}
          >
            <IconUICalendar />
          </InputAdornment>
        );

        return (
          <TextField
            autoComplete={autoComplete}
            data-testid={testId}
            defaultValue={defaultValue}
            disabled={disabled}
            fullWidth={fullWidth}
            groupLabel={groupLabel}
            helperText={helperText}
            id={id}
            margin={margin}
            name={name}
            placeholder={placeholder}
            required={required}
            inputProps={{
              ...props.inputProps,
              placeholder,
              // Override date value with masked string when date is represented by masked string
              value: isValueMasked ? dateValue : props.inputProps?.value,
            }}
            onBlur={handleBlur}
            onFocus={onFocus}
            ref={mergedRef}
            value={props.inputProps?.value}
            endAdornment={!hidePicker && inputIcon}
            {...inputProps}
            label={label}
            error={error}
          />
        );
      },
      [
        autoComplete,
        classes.mobileCalendarIcon,
        dateValue,
        defaultValue,
        disabled,
        error,
        fullWidth,
        groupLabel,
        handleBlur,
        handleOpen,
        helperText,
        hidePicker,
        id,
        inputProps,
        isValueMasked,
        label,
        margin,
        mergedRef,
        name,
        onFocus,
        placeholder,
        required,
        testId,
      ],
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker<InputValue, Dayjs>
          className={cx(classes.root, className)}
          components={{
            OpenPickerIcon: IconUICalendar,
            LeftArrowButton: ArrowButton,
            RightArrowButton: ArrowButton,
          }}
          componentsProps={{
            leftArrowButton: {
              arrowDirection: 'left',
              pickerDate,
              updatePickerDate: subtractPickerMonth,
            },
            rightArrowButton: {
              arrowDirection: 'right',
              pickerDate,
              updatePickerDate: addPickerMonth,
            },
          }}
          defaultCalendarMonth={dayjs()}
          disabled={disabled}
          disableHighlightToday
          disableOpenPicker={hidePicker || isValueMasked}
          inputFormat={format}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={renderInput}
          value={dateValue}
          OpenPickerButtonProps={{ className: classes.iconAdornment }}
          open={open}
          onAccept={handleAccept}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          DialogProps={{ className: cx(classes.paper, dialogClassName) }}
          PaperProps={{ className: classes.paper }}
          PopperProps={{
            anchorEl: () => textFieldRef?.current as HTMLInputElement,
            className: popperClassName,
          }}
          showToolbar
          toolbarFormat='ddd, MMM D'
          toolbarPlaceholder=''
          toolbarTitle=''
          desktopModeMediaQuery={
            !hidePicker ? theme.breakpoints.up('md') : theme.breakpoints.up('xs')
          }
        />
      </LocalizationProvider>
    );
  }),
);
