import type { Dispatch, FunctionComponent, SetStateAction, SVGProps } from 'react';
import { cloneElement, useEffect, useMemo, useState } from 'react';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import type { UserTokens } from '@ecp/utils/auth';
import { userAuth, useUserAuth } from '@ecp/utils/auth';
import { isTruthy, noop } from '@ecp/utils/common';

import { env } from '@ecp/env';
import {
  FullScreenModal,
  LoadingOverlay,
  ModalError,
} from '@ecp/features/servicing/shared/components';
import type { UseModalPathReturn } from '@ecp/features/servicing/shared/routing';
import { useSharedState, useUser } from '@ecp/features/servicing/shared/state';
import type { MfeCustomEvent } from '@ecp/features/servicing/shared/types';
import { getMfeEnv } from '@ecp/features/servicing/shared/util';

import { useStyles } from './MfeModal.styles';

export interface IconConfig {
  name: string;
  value: FunctionComponent<SVGProps<SVGSVGElement>>;
}

interface Props extends UseModalPathReturn {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  element: React.ReactElement;
  elementName: string;
  icons?: IconConfig[];
  title: string;
  useMock?: boolean;
  isPaperlessOn?: boolean;
}

interface ModalBodyProps extends Props {
  tokens: UserTokens;
  setToastMessage: Dispatch<SetStateAction<string | undefined>>;
}

const ModalBody: React.FC<ModalBodyProps> = (props) => {
  const {
    element,
    elementName,
    icons = [],
    isPaperlessOn,
    policyNumber,
    reset,
    tokens,
    useMock = false,
    setToastMessage,
  } = props;
  const { classes } = useStyles(undefined, { props });
  const [submitError, setSubmitError] = useState<string>();
  const [isMfeLoading, setIsMfeLoading] = useState(true);
  const [iconRef, setIconRef] = useState<HTMLDivElement | null>(null);

  const getToastMessage = (elementName: string): string => {
    let subject;
    if (elementName === 'add-driver') subject = ' to add a driver';
    if (elementName === 'add-vehicle') subject = ' to add a vehicle';

    return `Your request${subject} was successfully submitted; it is currently pending review.`;
  };

  const iconStrings = useMemo(() => {
    return icons
      .map(({ name }) => {
        const value = iconRef?.querySelector(`[data-icon-name='${name}']`)?.outerHTML;
        if (!value) return null;

        return { name, value };
      })
      .filter(isTruthy);
  }, [icons, iconRef]);
  const mfeRenderReady = iconStrings.length === icons.length;

  useEffect(() => {
    if (!mfeRenderReady) return noop;

    const handleEventListener = (event: MfeCustomEvent): void => {
      const eventDetails = event.detail;
      if (eventDetails.type === 'ctaClicked') {
        if (eventDetails.name === 'onCancel') reset();
      }
      if (eventDetails.type === 'info') {
        if (eventDetails.name === 'completedSuccess') {
          setToastMessage(getToastMessage(elementName));
        } else if (eventDetails.name === 'loadingStart') {
          setIsMfeLoading(true);
        } else if (eventDetails.name === 'loadingEnd') {
          setIsMfeLoading(false);
        }
      }
      if (eventDetails.type === 'error') {
        setSubmitError('unknown');
      }
    };

    // ensure mfe listeners are actually attached
    const mfeElement = document.querySelector(elementName);
    if (mfeRenderReady && !mfeElement) {
      throw Error(`failed to setup listener for <${elementName} /> component`);
    }

    mfeElement?.addEventListener('customEvent', handleEventListener as EventListener);

    return () => {
      mfeElement?.removeEventListener('customEvent', handleEventListener as EventListener);
    };
  }, [elementName, mfeRenderReady, reset, setToastMessage]);

  const mfeData = useMemo(
    () =>
      JSON.stringify({
        useMock,
        policyNumber,
        icons: iconStrings,
        isPaperlessOn,
        environment: getMfeEnv(env.runtimeEnv),
        partnerId: env.static.partnerId,
        sourceId: env.static.sourceId,
        tokens,
        traceId: interactionId.get(),
      }),
    [iconStrings, isPaperlessOn, policyNumber, tokens, useMock],
  );

  if (submitError) {
    return (
      <ModalError
        errorTitle='process your request at this time'
        errorDescription='processing your request'
      />
    );
  }

  return (
    <>
      {isMfeLoading && <LoadingOverlay />}
      <div className={classes.icons} ref={setIconRef}>
        {icons?.map(({ name, value: Icon }) => (
          <Icon key={name} data-icon-name={name} />
        ))}
      </div>
      {mfeRenderReady && cloneElement(element, { data: mfeData })}
    </>
  );
};

export const MfeModal: React.FC<Props> = (props) => {
  const { policyNumber, reset, title } = props;
  const { classes } = useStyles(undefined, { props });
  const [tokens, setTokens] = useState<UserTokens>();
  const [toastMessage, setToastMessage] = useState<string>();
  const [, setToast] = useSharedState<string | null>('snackBar');
  const { user } = useUser();
  useUserAuth();
  useEffect(() => {
    userAuth.isAuth &&
      userAuth.token.then((data) => {
        if (!data) throw Error('Somehow no user tokens for logged in user');
        setTokens(data);
      });
  }, []);

  const handleCloseModal = useMemo(
    () => () => {
      if (toastMessage) setToast(toastMessage);
      reset();
    },
    [reset, setToast, toastMessage],
  );

  if (!tokens || !policyNumber || !user?.userId) {
    return <LoadingOverlay />;
  }

  return (
    <FullScreenModal title={title} onCloseModal={handleCloseModal} classes={classes}>
      <ModalBody
        {...props}
        tokens={tokens}
        setToastMessage={setToastMessage}
        reset={handleCloseModal}
      />
    </FullScreenModal>
  );
};
